 /* * {
  border: 1px solid red !important;
}  */

:root {
  --et-green: #05aea5;
  --et-whiteblue: #f0f7f7;
  --et-black: #1d1d1d;
  --et-blue: #89DBFF;
  --et-gray: #324148;
  --et-violet: #9480FF;
  background-color: var(--et-black);
  cursor: default;
}

html {
  overflow-y: scroll;
  overscroll-behavior-y: none;
}

@font-face {
  font-family: 'Spotify-Bold';
  src: url('./fonts/Spotify-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: 'Spotify-Light';
  src: url('./fonts/Spotify-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: block;
}


/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: var(--et-violet) ;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--et-violet) ;
}


@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 100px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 100px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 100px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 100px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.chakra-alert {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 300px !important;
  bottom: 100px;
  background-color: var(--et-violet) !important;
  text-align: center;
}

.mini-progress-bar {
  position: absolute;
  width: 100%;
  margin-top: -4px;
  top: 0;
  left: 0;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.button {
  transition-duration: 0.3s;
}

button:hover, #cover:hover {
    opacity: 0.5;
}